import { makeStyles } from '@material-ui/core/styles'
import { FONTS } from './../../constants'

export const styles = makeStyles(theme => ({
  button: {
    backgroundColor: 'transparent',
    padding: 20,
    marginTop: 5,
    marginBottom: 5,
    border: `2px solid ${theme.palette.secondary.dark}`,
  },
  icon: {
    height: 24,
    width: 24.48,
    position: 'absolute',
    left: 22.55,
  },
  container: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    backgroundColor: 'transparent',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    height: 54,
    cursor: 'pointer',
    border: `2px solid ${theme.palette.common.black}`,
    margin: '5px auto',
    width: '100%',
    maxWidth: '460px',
    transition: '0.5s',
    ['&:hover']: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.light,
    },
    '& p': {
      color: 'inherit',
    },
  },
}))
