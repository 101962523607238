import { makeStyles } from '@material-ui/core/styles'
import { COLORS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  cookieBannner: {
    width: '100%',
    backgroundColor: COLORS.whiteStain,
    position: 'fixed',
    bottom: 0,
    paddingTop: 40,
    paddingBottom: 40,
    zIndex: 100,
    boxShadow: '0px 0 5px rgba(0, 0, 0, 0.1)',
  },
  container: {
    position: 'relative',
  },
  centerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerText: {
    display: 'inline-block',
  },
  logo: {
    width: 50,
    height: 50,
    marginRight: 20,
  },
  text: {
    marginBottom: 0,
  },
  button: {
    maxWidth: 220,
    width: '100%',
    position: 'absolute',
    color: 'black!important',
    right: 120,
    top: 0,
    margin: 0,
  },
  link: {
    textDecoration: 'none',
    ['&:active']: {
      color: '#000',
    },
    ['&:visited']: {
      color: '#000',
    },
  },
  linkText: {
    borderBottom: '1px solid #000',
    margin: 0,
    fontSize: '0.75rem',
    display: 'inline-block',
    color: '#000',
  },
  [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
    button: {
      maxWidth: 170,
      right: 20,
    },
  },
  [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
    logo: {
      width: 35,
      height: 35,
      marginRight: 10,
    },
    text: {
      fontSize: '1.45rem',
      marginBottom: 0,
    },
    button: {
      position: 'static',
      maxWidth: 170,
      width: '100%',
      marginTop: 5,
      marginBottom: 0,
      [`& > button`]: {
        height: 40,
      },
    },
    cookieBannner: {
      display: 'none',
      paddingTop: 10,
      paddingBottom: 20,
    },
  },
}))
