import { makeStyles } from '@material-ui/core/styles'
import { FONTS, COLORS, BREAKPOINTS } from './../../constants'

export const styles = makeStyles(theme => ({
  chirpyestTextLogoMinimized: {
    height: 42,
  },
  menuPadding: {
    padding: 0,
    overflowY: 'unset',
  },
  subMenuPadding: {
    paddingTop: '0px !important',
  },
  loginAndSignUp: {
    display: 'flex',
    paddingTop: 15,
    borderTop: '1px solid #000',
    ['& > button:first-child']: {
      backgroundColor: COLORS.white,
      '& p': {
        color: COLORS.black,
      },
      marginRight: 17,
    },
  },
  loggedIn: {
    display: 'flex',
    paddingTop: 15,
    borderTop: '1px solid #000',
    ['& > button:first-child']: {
      backgroundColor: COLORS.white,
      '& p': {
        color: COLORS.black,
      },
      // marginRight: 17,
    },
  },
  logoandClose: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 30,
  },
  chirpyestLogo: {
    marginTop: 20,
    marginLeft: 30,
  },
  footerNavLinksMobile: {
    display: 'flex',
  },
  closeButton: {
    border: 'none',
    backgroundColor: 'transparent',
    zIndex: 1, // fix button not clickable on ios safari
  },
  dialogTitleSection: {
    position: 'absolute',
    left: '20px',
    padding: 0,
    marginTop: 25,
    display: 'flex',
    justifyContent: 'flex-start',
    ['& > h2']: {
      textAlign: 'center',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      marginTop: 50,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      marginTop: 25,
    },
  },
  dialogTitleSectionTablet: {
    // left: '35px',
  },
  navListTablet: {
    marginTop: 70,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      marginTop: 40,
    },
  },
  dialogTablet: {
    ['& > .MuiDialog-scrollPaper']: {
      justifyContent: 'left',
    },
    ['& > .MuiDialog-scrollPaper > .MuiDialog-paperFullScreen']: {
      width: '375px',
    },
  },
  navList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginBottom: 40,
    ['& div.MuiAccordionSummary-content']: {
      margin: 0,
    },
    ['& div.MuiAccordionSummary-content > p']: {
      marginLeft: 7,
    },
  },
  mainNavList: {
    // ['& > p:nth-child(3)']: {
    //   padding: 0,
    // },
  },
  shopPadding: {
    padding: '0px !important',
  },
  subNavListItem: {
    // backgroundColor: '#FAFBFD',
    padding: '10px 0px !important',
    paddingLeft: '30px !important',
    background: 'white !important',
    textTransform: 'lowercase !important' as any,
  },
  subNavList: {
    marginBottom: 0,
    ['& > p']: {
      padding: '10px 0px',
      paddingLeft: 30,
      background: 'white',
      textTransform: 'lowercase',
    },
  },
  listItem: {
    borderTop: '1px solid #000',
    letterSpacing: '0.88px',
    lineHeight: '23px',
    textAlign: 'left',
    backgroundColor: '#FAFBFD',
    margin: 0,
    padding: '15px 0px',
    paddingLeft: 7,
    fontSize: '0.875rem' /*equals 14px*/,
    fontFamily: FONTS.Graphik.GraphikRegular,
    display: 'inline-block',
    textTransform: 'uppercase',
    ['& a']: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      verticalAlign: '-webkit-baseline-middle',
    },
  },
  joinLinkSpan: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: '10px',
  },
  copiedCaption: {
    color: 'white',
    backgroundColor: '#175972',
    opacity: '0.5',
    display: 'flex',
    height: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
  },
  actionButton: {
    marginTop: 20,
    width: '100%',
    maxWidth: 255,
    margin: '0 auto',
  },
  chirpyestTextLogoBtn: {
    border: 'none',
    background: 'transparent',
  },
  menuContainer: {
    marginTop: 20,
    width: '100%',
    margin: 'auto',
    position: 'relative',
  },
  shopMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    margin: 'auto',
    position: 'relative',
    alignItems: 'center',
    zIndex: 1000,
  },
  shopMenuColContainer: {
    position: 'relative',
  },
  shopMenuLink: {
    textDecoration: 'none',
    color: COLORS.black,
  },
  shopMenuHeading: {
    borderBottom: '1px solid black',
    marginBottom: 40,
  },
  shopMenuItemIcon: {
    width: 60,
    height: 60,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: -1,
    borderRadius: 5,
  },
  fashion: {
    top: -6,
    right: -51,
    background: '#FEF2F3',
  },
  home: {
    top: 11,
    right: 68,
    background: '#E7EFED',
  },
  beauty: {
    top: -9,
    right: -57,
    background: '#FDE4E3',
  },
  lifestyle: {
    top: -3,
    right: 84,
    background: '#D1DEE3',
  },
  profileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatarContainer: {
    display: 'block',
    position: 'relative',
    width: 50,
    height: 0,
    paddingBottom: 50,
    background: 'black',
    borderRadius: 999,
    marginBottom: 5,
  },
  birdAvatar: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: 40,
    width: 40,
    margin: 'auto',
    display: 'block',
    position: 'absolute',
  },
  userAvatar: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 999,
  },
  username: {
    fontFamily: FONTS.Graphik.GraphikLight,
    fontSize: 12,
    letterSpacing: '0.33px',
    lineHeight: '22px',
    textAlign: 'center',
    marginBottom: 21,
  },
  profileNav: {
    backgroundColor: '#FAFBFD',
    borderTop: '1px solid #445055',
    padding: '15px 20px',
  },
  cashBack: {
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: 12,
    letterSpacing: '0.33px',
    lineHeight: '22px',
    textAlign: 'center',
    marginBottom: 0,
  },
  disabled: {
    fontFamily: FONTS.Graphik.GraphikLight,
    cursor: 'default',
    marginTop: 20,
    pointerEvents: 'none',
    color: COLORS.lightGrey,
  },
  chirpyestLogoLink: {
    ['& > img']: {
      width: 44,
      height: 44,
      marginTop: 40,
      marginLeft: 15,
    },
  },
}))
