import * as React from 'react'
import { styles } from './styles'
import { Typography } from '@material-ui/core'
import classNames from 'classnames'
interface OutlinedButtonProps {
  onClick: Function
  label: string
  font?: string
  customStyle?: any
}

const CHROutlinedButton = ({
  onClick,
  label,
  customStyle,
}: OutlinedButtonProps) => {
  const classes = styles()
  const buttonClass = classNames({
    [classes.container]: true,
    [customStyle]: customStyle,
  })
  return (
    <button type="button" className={buttonClass} onClick={() => onClick()}>
      <Typography variant="button" component="p">
        {label}
      </Typography>
    </button>
  )
}

export default CHROutlinedButton
