import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  dialogContainer: {
    fontFamily: FONTS.Graphik.GraphikRegular,
    padding: '76px 80px 76px 80px',
    margin: '0 auto',
    minWidth: 600,

    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      margin: '0 auto',
      padding: '76px 0',
      minWidth: 'unset',
      width: '85%',
    },
  },

  closeButton: {
    position: 'absolute',
    bottom: 40,
    right: 0,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },

  dialogTitleSection: {
    position: 'relative',
    padding: 0,
    marginTop: 60,
    marginBottom: 60,
    ['& > h2']: {
      textAlign: 'center',
    },
  },
}))
