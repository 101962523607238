import React, { useEffect, useState } from 'react'
import { styles } from './styles'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@material-ui/core'
import ContentContainer from '../../components/contentContainer'
import { ENDPOINTS, SHOP_MENU_CONFIG } from '../../constants'
import axios from '../../axios'
import { Link, navigate } from 'gatsby'
import arrowIcon from '../../assets/images/arrow-left.svg'

interface CategoriesMenuProps {
  t: TFunction
  openStatus: boolean
  handleClose: () => void
  closeMenu: () => void
}

const CategoriesMenu = ({
  t,
  openStatus,
  handleClose,
  closeMenu,
}: CategoriesMenuProps) => {
  const classes = styles()

  const [categories, setCategories] = useState()
  const [openedTab, setOpenedTab] = useState('all')
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const categoriesData = await axios.get(ENDPOINTS.categories)
        setCategories(categoriesData.data.data)
        setIsPageLoading(false)
      } catch (err) {
        setIsError(true)
        setIsPageLoading(false)
      }
    })()
  }, [])

  const ShopMenuColContainer = ({ item, onClick }) => (
    <div onClick={onClick} className={classes.shopMenuColContainer}>
      <Link className={classes.shopMenuLink} to={item.link}>
        <Typography
          className={classes.shopMenuHeading}
          variant="h1"
          component="p"
        >
          {t(`shared.${item.title}`)}
        </Typography>
      </Link>
      <div
        className={[classes.shopMenuItemIcon, classes[item.title]].join(' ')}
      >
        <img width={36} height={38} src={item.icon} alt="shopmenu" />
      </div>
    </div>
  )

  return (
    <Box>
      {openStatus && (
        <Box className={classes.menuContainer}>
          <ContentContainer>
            <IconButton
              onClick={() => {
                if (openedTab === 'all') {
                  handleClose()
                } else {
                  setOpenedTab('all')
                }
              }}
              style={{ position: 'absolute', left: '-20px' }}
            >
              <img src={arrowIcon} alt="arrow-icon" />
            </IconButton>
            <div className={classes.shopMenuContainer}>
              {openedTab === 'all' ? (
                Object.values(SHOP_MENU_CONFIG).map(item => (
                  <ShopMenuColContainer
                    onClick={() => setOpenedTab(item.title)}
                    item={item}
                  />
                ))
              ) : (
                <>
                  <ShopMenuColContainer
                    onClick={() => {
                      navigate(`/category/${openedTab}`)
                      closeMenu()
                    }}
                    item={SHOP_MENU_CONFIG[openedTab]}
                  />
                  <List dense>
                    {!isPageLoading && !isError && (
                      <>
                        {categories[openedTab].map(category => (
                          <ListItem style={{ paddingLeft: 0 }}>
                            <Link
                              onClick={closeMenu}
                              className={classes.shopMenuLink}
                              to={`/category/${openedTab}_${category.name}`}
                            >
                              <ListItemText primary={category.name} />
                            </Link>
                          </ListItem>
                        ))}
                        <ListItem style={{ paddingLeft: 0 }}>
                          <Link
                            onClick={closeMenu}
                            className={classes.shopMenuLink}
                            to={`/category/${openedTab}`}
                          >
                            <ListItemText primary={t('shared.viewAll')} />
                          </Link>
                        </ListItem>
                      </>
                    )}
                  </List>
                </>
              )}
            </div>
          </ContentContainer>
        </Box>
      )}
    </Box>
  )
}

export default withTranslation()(CategoriesMenu)
