import React from 'react'
import Tooltip, {
  TooltipProps,
  TooltipClassKey,
} from '@material-ui/core/Tooltip'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS } from '../../constants'

type TooltipClassKeysType = {
  [K in TooltipClassKey]?: string
}
interface MouseOverPopoverProps {
  children?: React.Node
  title?: React.Node
  placement?: any
  open?: boolean
  customStyle?: any
  overrideClasses?: TooltipClassKeysType
}

const useStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.black,
    color: COLORS.white,
    width: 270,
    height: 65,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      display: 'none',
    },
  },
  popper: {
    pointerEvents: 'visible',
    zIndex: 100,
  },
}))

function CustomPopUp(props: TooltipProps) {
  const classes = useStyles()

  return (
    <Tooltip
      {...props}
      arrow
      classes={{
        ...(props.classes || {}),
        arrow: props.classes?.arrow || classes.arrow,
        tooltip: props.classes?.tooltip || classes.tooltip,
        popper: props.classes?.popper || classes.popper,
      }}
    />
  )
}

const MouseOverPopover = ({
  title,
  placement,
  children,
  open,
  customStyle,
  overrideClasses,
}: MouseOverPopoverProps) => {
  return (
    <CustomPopUp
      open={open}
      placement={placement}
      title={title}
      className={customStyle}
      classes={overrideClasses}
    >
      {children}
    </CustomPopUp>
  )
}

export default MouseOverPopover
